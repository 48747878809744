import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import useBranches from '../../data/useBranches';

const AutosuggestInput = ({ selectedValue, setSelectedValue }) => {
    const [searchValue, setSearchValue] = useState("");
    const [suggestedItems, setSuggestedItems] = useState([]);

    const branches = useBranches();

    const suggestions = branches.map(branch => ({
        id: branch.id,
        name: branch.name,
        content: `<p><strong>${branch.name}</strong></p><p>${branch.address}</p>`
    }));

    const renderSuggestion = (suggestion) => (
        <div dangerouslySetInnerHTML={{ __html: suggestion.content }} />
    );

    const getSuggestionValue = (suggestion) => suggestion.name;

    const onSuggestionsFetchRequested = ({ value }) => {
        const filteredSuggestions = suggestions.filter(suggestion =>
            suggestion.name.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestedItems(filteredSuggestions);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestedItems([]);
    };

    const handleFocus = () => {
        setSuggestedItems(suggestions);
    };

    useEffect(() => {
        if (selectedValue?.branch_id) {
          const branch = suggestions.find(
            (branch) => branch.id === selectedValue.branch_id
          );
          if (branch) {
            setSearchValue(branch.name);
          }
        }
      }, [selectedValue, suggestions]);

    const onSuggestionSelected = (_, { suggestion }) => {
        setSelectedValue((prev) => ({
            ...prev,
            keyword: suggestion.name,
            branch_id: suggestion.id
          }));
        setSearchValue(suggestion.name);

        setTimeout(() => {
            setSuggestedItems([]);
        }, 100);
    };

    return (
        <Autosuggest
            suggestions={suggestedItems}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={onSuggestionSelected}
            alwaysRenderSuggestions={true}
            inputProps={{
                placeholder: "Search here....",
                value: searchValue,
                onChange: (_, { newValue }) => {
                    setSelectedValue((prev) => ({...prev, keyword: newValue, branch_id: ''}));
                    setSearchValue(newValue);
                },
                onFocus: handleFocus
            }}
        />
    );
};

export default AutosuggestInput;
