import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

const InstructionModal = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const isFirstVisit = localStorage.getItem('firstVisit') === null;
        if (isFirstVisit) {
            setShowModal(true);
            localStorage.setItem('firstVisit', 'true');
        }
    }, []);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <>
            {showModal && (
                <Modal
                    show={showModal}
                    onHide={handleClose}
                    dialogClassName="modal-dialog modal-dialog-centered"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">Welcome!</h5>
                        <button type="button" className="close" onClick={handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center">
                        <img src="/younic-logo.png" alt="" />
                        <h6>If you need any assistance, please contact us directly.</h6>
                        <strong>Helpline: +88 01325-05 19 15, +88 01711-70 47 29</strong>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default InstructionModal;