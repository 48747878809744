import React, { useRef, useState, useEffect } from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap-daterangepicker';
import $ from 'jquery';
import moment from 'moment';

const BootstrapDatePicker = ({ onDateSelect, initialSettings, isRange, initialDate, placeholderText, onChange }) => {
  const pickerRef = useRef(null);
  const [dateValue, setDateValue] = useState(''); // Controlled state for date value

  useEffect(() => {
    const $picker = $(pickerRef.current);
    const settings = {
      singleDatePicker: !isRange,
      autoApply: true,
      autoUpdateInput: false,
      showDropdowns: false,
      cancel: true,
      locale: { format: 'DD-MM-YYYY' },
      cancelClass: 'btn btn-secondary',
      applyClass: 'btn btn-primary',
      minDate: moment().startOf('day'),
      ...initialSettings,
    };

    if (isRange && initialDate) {
      const startDate = moment(initialDate[0], 'DD-MM-YYYY');
      const endDate = moment(initialDate[1], 'DD-MM-YYYY');
      settings.startDate = startDate;
      settings.endDate = endDate;
      setDateValue(`${startDate.format('DD-MM-YYYY')} ~ ${endDate.format('DD-MM-YYYY')}`);
    } else if (!isRange && initialDate) {
      const startDate = moment(initialDate, 'DD-MM-YYYY');
      settings.startDate = startDate;
      setDateValue(startDate.format('DD-MM-YYYY'));
    }

    $picker.daterangepicker(settings, (start, end) => {
      if (onDateSelect) {
        if (isRange) {
          onDateSelect({
            start: start.format('DD-MM-YYYY'),
            end: end.format('DD-MM-YYYY'),
          });
        } else {
          onDateSelect(start.format('DD-MM-YYYY'));
        }
      }
      if (isRange) {
        setDateValue(`${start.format('DD-MM-YYYY')} ~ ${end.format('DD-MM-YYYY')}`);
      } else {
        setDateValue(start.format('DD-MM-YYYY'));
      }
    });

    $picker.on('apply.daterangepicker', (ev, picker) => {
      const selectedDate = picker.startDate.format('DD-MM-YYYY');
      if (isRange) {
        const start = picker.startDate.format('DD-MM-YYYY');
        const end = picker.endDate.format('DD-MM-YYYY');
        onDateSelect({ start, end });
        setDateValue(`${start} ~ ${end}`);
      } else {
        onDateSelect(selectedDate);
        setDateValue(selectedDate);
      }
    });
  
    return () => {
      $picker.data('daterangepicker').remove();
    };
  }, [onDateSelect, initialSettings, isRange, initialDate]);

  const handleInputChangeInternal = (e) => {
    const value = e.target.value;
    setDateValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <input
      type="text"
      ref={pickerRef}
      className="form-control form-control-lg"
      value={dateValue}
      placeholder={placeholderText ? placeholderText : 'Select date'}
      onChange={handleInputChangeInternal}
    />
  );
};

export default BootstrapDatePicker;
