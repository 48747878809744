import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import useBranches from '../../data/useBranches';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
const options = {
    loop: false,
    margin: 0,
    nav: true,
    autoWidth: true,
    items: 2,
    navContainer: false,
    navElement: "div",
    dots: false,
};

const FilterSection = () => {
    const branches = useBranches();

    const navigate = useNavigate();

    const handleFilter = (e, branch_id, keyword) => {
        e.preventDefault(); 
        const queryParams = new URLSearchParams({
            keyword: keyword,
            branch_id: branch_id
        });

        navigate({
            pathname: '/',
            search: `?${queryParams.toString()}`,
        });
    };

    return (
        <>
            <section className="filter_section_wrapper">
                <div className="container-fluid mb-3">
                    <div className="col-sm-11 mx-auto">
                        <div className="row">
                            <div className="col-md-12 ">
                                <OwlCarousel className="icon-menu filter-carousel owl-carousel owl-theme" {...options}>
                                    {branches && branches.length > 0 ? (
                                        <>
                                            <div 
                                                className="item"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate('/');
                                                }}
                                            >
                                                <Link>
                                                    <img src="/assets/images/7969735.png" alt=" All Rooms" />
                                                    All Rooms
                                                </Link>
                                            </div>
                                            {branches.map((branch, index) => (
                                                <div 
                                                    className="item" 
                                                    key={index}
                                                    onClick={(e) => handleFilter(e, branch.id, branch.name)}
                                                >
                                                    <Link>
                                                        <img
                                                            src={branch.thumbnail.data_url || "/assets/images/7969735.png"}
                                                            alt={branch.name}
                                                        />
                                                        {branch.name}
                                                    </Link>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {Array.from({ length: 6 }).map((_, index) => (
                                                <div className="item" key={index}>
                                                    <div className="ph-item p-0 border-0">
                                                        <div className="ph-col-12 p-0">
                                                            <div className="ph-col-2">
                                                                <div
                                                                    className="ph-avatar"
                                                                    style={{ width: "50px", margin: "0 auto" }}
                                                                ></div>
                                                            </div>
                                                            <div className="ph-row">
                                                                <div className="ph-col-12 big"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </OwlCarousel>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default FilterSection;
