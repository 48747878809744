import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/bootstrap/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './assets/plugins/daterangepicker/daterangepicker.css';

import 'datatables.net-dt';
import 'datatables.net-bs4';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-dt/css/dataTables.dataTables.min.css';

import 'react-toastify/dist/ReactToastify.css';
import "../node_modules/placeholder-loading/dist/css/placeholder-loading.min.css";
import './assets/css/style.css';
import './assets/css/responsive.css';
import './App.css';
import reportWebVitals from './reportWebVitals';
import { ModalProvider } from './_context/ModalContext';
import { UserProvider } from './_context/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ModalProvider>
      <UserProvider >
        <App />
      </UserProvider>
    </ModalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
